<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-content>
			<md-card class="application-card md-layout-item md-size-50 md-small-size-100" md-elevation="0">
				<md-card-header style="text-align: center">
					<div class="md-title">Applications</div>
				</md-card-header>
				<md-card-content style="text-align: center">
					<md-divider/>
					<div>
						<div v-for="appRow, i in allowedApps" :key="i" class="md-layout" style="margin-bottom: 10px; margin-top: 10px">
							<div v-for="app in appRow" :key="app.rolePrefix" class="md-layout-item">
								<a style="text-decoration: none" :href="app.url">
									<md-icon v-if="!app.icon.endsWith('.svg')" class="md-size-3x">{{app.icon}}</md-icon>
									<md-icon v-else :md-src="app.icon" class="md-size-3x"/>
									<br/>
								</a>
								<a style="font-size: 20px" :href="app.url">{{app.name}}</a>
							</div>
						</div>
					</div>
					<md-divider/>
				</md-card-content>
			</md-card>
			<router-view />
		</md-app-content>
	</md-app>
</template>

<script>
import Vue from 'vue'
import Toolbar from '@/components/Common/Toolbar.vue'
import config from '@/../config'
export default {
	name: 'Home',
	components: { toolbar: Toolbar },
	async created() {
		try {
			this.user = await this.$apiService.user.getOne(Vue.user().id)
		} catch (e) {
			this.$snackbar.show('Could not load user data')
		}
	},
	data: function() {
		return {
			user: null,
			apps: [{
				name: 'UUID Management',
				icon: 'precision_manufacturing',
				url: config.UUID_URL,
				rolePrefix: 'UUID'
			}, {
				name: 'LR Distribution',
				icon: 'rocket',
				url: config.LR_DISTRIBUTION_URL,
				rolePrefix: 'LRD'
			}, {
				name: 'Protocol Documentation',
				icon: 'description',
				url: config.PROTOCOL_DOCU_URL,
				rolePrefix: 'PROTOCOL'
			}, {
				name: 'LR Production',
				icon: 'receipt_long',
				url: config.LR_PRODUCTION_URL,
				rolePrefix: 'LRT'
			}, {
				name: 'NXT Cloud',
				icon: 'nxt_icon.svg',
				url: config.NXT_CLOUD_URL,
				rolePrefix: 'NXTC'
			},{
				name: 'Occhio Electronics',
				icon: 'occhio_electronics.svg',
				url: config.OCCHIO_ELECTRONICS_CLOUD_URL,
				rolePrefix: 'OE'
			}]
		}
	},
	computed: {
		allowedApps() {
			if (!this.user) {
				return []
			}
			this.user.roles.push('PROTOCOL')
			const apps = this.apps.filter(app => {
				return this.user.roles.some(role => role.startsWith(app.rolePrefix))
			})
			const apps2d = []
			for (let i = 0; i < apps.length; i += 2) {
				apps2d.push(apps.slice(i, i + 2))
			}
			return apps2d
		},
	}
}
</script>

<style scoped>
.hidden {
	visibility: hidden;
}
.application-card {
	margin: auto;
}
</style>
