<template>
	<md-app>
		<md-app-content>
			<md-card class="login-card md-layout-item md-size-30 md-small-size-100">
				<md-card-header style="text-align: center">
					<div class="md-title">Sign In</div>
				</md-card-header>
				<md-card-content style="text-align: center">
					<md-divider/>
					<div class="md-layout" style="margin-top: 10px">
						<div class="md-layout-item md-caption" style="font-size: 14px">
							LR Innovations Lab
						</div>
						<div class="md-layout-item md-caption" style="font-size: 14px">
							Occhio
						</div>
					</div>
					<div class="md-layout" style="margin-bottom: 10px; margin-top: 2px">
						<div class="md-layout-item">
							<div class="loginButtonBox" @click="googleLogin">
								<img src="google-logo.svg" width="18px"/>
								<span class="loginBtnText">Google</span>
							</div>
						</div>
						<div class="md-layout-item">
							<div class="loginButtonBox" @click="microsoftLogin">
								<img src="microsoft.svg" width="18px"/>
								<span class="loginBtnText">Microsoft</span>
							</div>
						</div>
					</div>
					<md-divider/>
				</md-card-content>
			</md-card>
		</md-app-content>
	</md-app>
</template>

<script>
import config from '@/../config'
export default {
	name: 'Login',
	data () {
		return {}
	},
	methods: {
		googleLogin() {
			window.location.href = config.GOOGLE_AUTH_URL;
		},
		microsoftLogin() {
			window.location.href = config.MICROSOFT_AUTH_URL
		}
	}
}
</script>

<style scoped>
	.login-card {
		margin: auto;
		margin-top: 10%;
	}

	.loginButtonBox {
		padding: 8px;
		border-radius: 4px;
		margin: 5px 0;
		opacity: 0.85;
		display: inline-block;
		font-size: 14px;
		text-decoration: none; /* remove underline from anchors */
		cursor: pointer;
		border-style: solid;
		border-width: 1px;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
		transition: background-color .3s, box-shadow .3s;
		background-color: white;
		border-color: grey;
		text-align: left;
	}
	.loginButtonBox:hover {
		box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
	}
	.loginButtonBox:active {
		background-color: #eeeeee;
	}
	.loginBtnText {
		margin-left: 24px;
		font-size: 14px;
	}
</style>
